<template>
  <div class="order-collect">
    <div class="cart-review-title">
      <h1 class="page-title">Collect items</h1>

      <CartSteps step="collect" />

      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <div class="page-content">
            <div class="page-header">
              <h3>Last step!</h3>
              <p>
                We need to include names on tickets
              </p>
            </div>
            <b-form @submit.stop.prevent="onSubmit">
              <div class="order-listing">
                <div class="form-alert" v-if="!namesValid">
                  <div class="container">
                    <div class="row">
                      <div class="col-1">
                        <AlertCircleIcon class="color-bg-svg" />
                      </div>
                      <div class="col-11">
                        <p>
                          You have one ticket with the same name. Tickets can only have one name.
                          <!-- Go to item -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="order-item" v-for="item in orderItems" :key="item.id">
                  <h3 class="item-title">{{ item.title }}</h3>
                  <p class="item-description">{{ item.description }}</p>

                  <p>Confirm the ticket name:</p>

                  <b-form-group label="Name / Chosen name on Ticket *" class="custom">
                    <b-form-input v-model="item.name" required trim />
                  </b-form-group>
                </div>

              </div>

              <div class="d-flex justify-content-end">
                <b-btn :disabled="!namesValid" type="submit" variant="primary">Send to My Email</b-btn>
              </div>
            </b-form>
          </div>
        </div>

      </div>


    </div>
  </div>



</template>

<script>
import CartSteps from "@/components/CartSteps"
import AlertCircleIcon from "@/assets/icons/alert-circle.svg";
export default {
  name: 'OrderCollect',
  metaInfo: {
    title: 'Collect items',
  },
  components: {
    CartSteps,
    AlertCircleIcon
  },
  computed: {
    namesValid() {
      let ticketNames = this.orderItems.filter(el => el.item_type == 'ticket').map(el => el.name);
      let uniqueNames = [...new Set(ticketNames)];

      let busTicketNames = this.orderItems.filter(el => el.item_type == 'bus_ticket').map(el => el.name);
      let uniqueBusNames = [...new Set(busTicketNames)];

      if(this.orderItems.length == 0) {
        return true;
      } else if (uniqueNames.length == ticketNames.length && busTicketNames.length == uniqueBusNames.length) {
        return true;
      } else {
        return false;
      }

    }
  },
  data() {
    return {
      orderItems: [
        {
          id: 0,
          title: "Early bird festival ticket",
          description: "+ Corona test",
          name: "",
          item_type: "ticket",
        },
        {
          id: 1,
          title: "Early bird festival ticket",
          description: "+ Corona test",
          name: "",
          item_type: "ticket",
        },
        {
          id: 2,
          title: "Shutter",
          description: "Dessau to Ferropolis - Friday - 14:00",
          name: "",
          item_type: "bus_ticket",
        },
        {
          id: 3,
          title: "Shutter",
          description: "Dessau to Ferropolis - Friday - 14:00",
          name: "",
          item_type: "bus_ticket",
        },

      ]
    }
  },
  methods: {
    onSubmit() {
      this.$router.push(`/orders/complete`);
    }
  }
}
</script>

<style lang="scss">
.order-collect {
  .cart-review-title {
    .page-title {
      margin: 6rem 0 3rem;
      font-size: 76px;
      font-weight: 700;
      text-align: center;
    }

  }

  .page-content {
    .page-header {
      h3 {
        font-weight: 700;
        font-size: 47px;
        line-height: 56px;
      }

      p {
        font-size:18px;
        font-weight: 400;
        line-height: 27px;
      }
    }

    .order-listing {
      margin-top: 60px;
      .order-item {
        &:not(:first-child) {
          margin-top: 7px;
        }

        background: var(--whole-bg-lighter);;
        padding: 20px 30px;

      }
    }

    .btn {
      margin-top: 2rem;
      border-radius: 0;
      font-weight: 500;
      font-size: 18px;
      text-transform: uppercase;
    }
  }
}
</style>
